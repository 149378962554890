// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-clinical-data-js": () => import("./../../../src/pages/clinical-data.js" /* webpackChunkName: "component---src-pages-clinical-data-js" */),
  "component---src-pages-get-in-touch-js": () => import("./../../../src/pages/get-in-touch.js" /* webpackChunkName: "component---src-pages-get-in-touch-js" */),
  "component---src-pages-important-safety-information-js": () => import("./../../../src/pages/important-safety-information.js" /* webpackChunkName: "component---src-pages-important-safety-information-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-patient-404-js": () => import("./../../../src/pages/patient/404.js" /* webpackChunkName: "component---src-pages-patient-404-js" */),
  "component---src-pages-patient-about-sprix-js": () => import("./../../../src/pages/patient/about-sprix.js" /* webpackChunkName: "component---src-pages-patient-about-sprix-js" */),
  "component---src-pages-patient-get-sprix-now-js": () => import("./../../../src/pages/patient/get-sprix-now.js" /* webpackChunkName: "component---src-pages-patient-get-sprix-now-js" */),
  "component---src-pages-patient-important-safety-information-js": () => import("./../../../src/pages/patient/important-safety-information.js" /* webpackChunkName: "component---src-pages-patient-important-safety-information-js" */),
  "component---src-pages-patient-index-js": () => import("./../../../src/pages/patient/index.js" /* webpackChunkName: "component---src-pages-patient-index-js" */),
  "component---src-pages-patient-resources-js": () => import("./../../../src/pages/patient/resources.js" /* webpackChunkName: "component---src-pages-patient-resources-js" */),
  "component---src-pages-patient-sitemap-js": () => import("./../../../src/pages/patient/sitemap.js" /* webpackChunkName: "component---src-pages-patient-sitemap-js" */),
  "component---src-pages-patient-sprix-direct-js": () => import("./../../../src/pages/patient/sprix-direct.js" /* webpackChunkName: "component---src-pages-patient-sprix-direct-js" */),
  "component---src-pages-patient-what-are-nsaids-js": () => import("./../../../src/pages/patient/what-are-nsaids.js" /* webpackChunkName: "component---src-pages-patient-what-are-nsaids-js" */),
  "component---src-pages-prescribing-sprix-js": () => import("./../../../src/pages/prescribing-sprix.js" /* webpackChunkName: "component---src-pages-prescribing-sprix-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */)
}

