const siteIndications = {
  hcp: 'hcp',
  patient: 'patient',
};

const metaData = {
  SITE_NAME: `SPRIX®`,
  SITE_DEFAULT_HCP_TITLE: `SPRIX® | Official Site for HCPs`,
  SITE_DEFAULT_PATIENT_TITLE: `SPRIX® | Official Site for Patient`,
  SITE_DEFAULT_HCP_DESCRIPTION: `Learn about SPRIX (ketorolac tromethamine). Please see the full Prescribing Information, including Boxed Warning and Patient Medication Guide.`,
  SITE_DEFAULT_PATIENT_DESCRIPTION: `Learn about SPRIX (ketorolac tromethamine). Please see the full Prescribing Information, including Boxed Warning and Patient Medication Guide.`,
  SITE_DEFAULT_KEYWORDS: ['Sprix', 'hcp', 'patient'],
  PATIENT_JOB_CODE: `APL-SPRX-0001 Rev. 4`,
  HCP_JOB_CODE: `APL-SPRX-0001 Rev. 4`,
  CURRENT_YEAR: new Date().getFullYear(),
};

/**
 * HEADER_TYPE : fixed or regular
 * ISI_OPTIONS["TYPE"] : sticky or regular
 */
const ISI_HEIGHT = 120;
const siteOptions = {
  INDICATIONS: { ...siteIndications },
  HEADER_TYPE: 'fixed',
  STICKY_HEIGHT: ISI_HEIGHT,
  ISI_OPTIONS: {
    TYPE: 'sticky',
    STICKY_STYLES: {
      height: ISI_HEIGHT,
    },
  },
};
const hcpModalInfo = {
  TITLE: `YOU ARE NOW ENTERING A US
HEALTHCARE PROFESSIONAL SITE.`,
  BODY: `The information contained on this site is scientific in nature and was designed specifically for US Healthcare Professionals.`,
  BUTTON_ONE_CAPTION: `NO, I AM NOT A US HEALTHCARE PROFESSIONAL`,
  BUTTON_TWO_CAPTION: `YES, I AM A US HEALTHCARE PROFESSIONAL`,
  CLASS_NAME: `sprix-modal sprix-hcp-modal`,
};
const externalLinkInfo = {
  TITLE: `BRANDX Website Message`,
  BODY: `You are going away from BRANDX website. Do you want to continue?`,
  LINK_CLASS_NAME: `brandx-external-link`,
  OVERLAY_CLASS_NAME: `sprix-modal sprix-external-modal`,
};

const appConfigs = {
  metaData: metaData,
  siteOptions: siteOptions,
  hcpModalInfo: hcpModalInfo,
  externalLinkInfo: externalLinkInfo,
};

export default appConfigs;
